/* WalletDonation Container */
.container.walletDonation_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

/* Center the icon and title */
.walletDonation_header {
    display: flex;
    align-items: center;
}

/* Adjust the icon */
.walletDonation_icon {
    font-size: 3rem;
    color: var(--color-primary);
    /* Add margin to create spacing between icon and title */
}



/* WalletDonation Info */
.walletDonation_info {
    text-align: center;
}

.walletDonation_info h4 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
}

.walletDonation_info p {
    font-size: 1rem;
    color: var(--color-text-secondary);
    margin-bottom: 1.5rem;
}

/* QR Code Frame */
.qr-code-frame {
    border: 2px solid var(--color-primary);
    border-radius: 0.5rem;
    padding: 1rem;
    cursor: pointer;
    transition: border-color 0.3s ease;
    display: inline-block;
}

.qr-code-frame:hover {
    border-color: var(--color-primary-hover);
}

/* Copied Text */
.copied-text {
    font-size: 1rem;
    color: var(--color-primary);
    text-align: center;
    margin-top: 0.5rem;
}

/* Wallet Address */
.wallet-address {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.address {
    font-size: 1rem;
    color: var(--color-primary);
    cursor: pointer;
}

.address:hover {
    text-decoration: underline;
}

/* WalletDonation Button */
.btn-primary {
    background-color: var(--color-primary);
    color: #fff;
    font-size: 1rem;
    padding: 0.75rem 2rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: var(--color-primary-hover);
}

/* Custom Select Styles */
.custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    border: 1px solid var(--color-primary-variant);
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    width: 100%;
    color: var(--color-primary);
    background-image: url('data:image/svg+xml;utf8,<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>');
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
}

.custom-select:focus {
    outline: none;
    border-color: var(--color-primary);
}

/* Style the dropdown arrow icon */
.custom-select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0.5rem;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.25rem 0.25rem 0 0.25rem;
    border-color: var(--color-primary);
}

/* Style the donation amount input */
.donation-amount-input {
    width: 100%;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid var(--color-primary-variant);
    font-size: 1rem;
    color: var(--color-primary);
    background-image: none;
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    cursor: pointer;
}

.donation-amount-input:focus {
    outline: none;
    border-color: var(--color-primary);
}