/* header.css */
header {
    text-align: center;
    padding-top: 4rem;
    /* Reduce the padding to make the header shorter */
    overflow: hidden;
    position: relative;
}

.header_container {
    text-align: center;
    height: 100%;
    /* Keep the header_container height at 100% */
    position: relative;
}

/* Other styles remain the same */

.text-box {
    background: var(--color-bg-variant);
    padding: 2.4rem 2rem;
    /* Adjust the padding as needed */
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    width: 100%;
    margin: 0 auto;
    text-align: center;
    box-sizing: border-box;
    max-width: 60%;
    /* Adjust the max-width as needed */
    position: relative;
    z-index: 1;
    /* Add a z-index to ensure it's above the evolv div */
}

/* Other styles remain the same */


.text-box:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.text-box h3 {
    text-align: center;
    color: var(--color-primary);
}



/* #################### CTA #################### */
.cta {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 0rem;
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    flex-direction: unset;
}

/* #################### SOCIALS #################### */
.header_socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 1rem;
    bottom: 3rem;
    font-size: 28px;
}

.header_socials::after {
    content: '';
    width: 2px;
    height: 2rem;
    background: var(--color-primary);
}

/* #################### EVOLV #################### */
.evolv {
    background: linear-gradient(var (--color-primary), transparent);
    width: 22rem;
    height: 27rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 0.5rem;
    margin-bottom: 0rem;
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    padding: rem 0.5rem 0.5rem 0.5rem;
}

/* #################### SCROLL DOWN #################### */
.scroll_down {
    position: absolute;
    right: 0.02rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }

    section {
        margin-top: 6rem;
    }
}

/* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section>h2 {
        margin-bottom: 2rem;
    }
}