.portfolio_container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio_item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image {
    border-radius: 1.5rem;
    text-align: center;
    /* Center the text horizontally */
    overflow: hidden;
}

.portfolio_item h3 {
    margin: 1.2rem auto 0.5rem; /* Updated margin for title */
    text-align: center; /* Center the text horizontally */
}

.portfolio_item-description {
    text-align: center; /* Center the description text horizontally */
    margin: 0.5rem 0 1rem; /* Add margin between title and description */
}

.portfolio_item-cta {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
    gap: 1rem;
    margin-bottom: 1rem;
}


/* #################### MEDIA QUERIES (MEDIUM DEVICES) #################### */
@media screen and (max-width: 1024px) {
    .portfolio_container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* #################### MEDIA QUERIES (SMALL DEVICES) #################### */
@media screen and (max-width: 600px) {
    .portfolio_container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}